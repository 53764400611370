import React from 'react'

export default function WorkersTable() {

    const Head=()=>{
        return <thead>
        <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Organization</th>
            <th>Tasks</th>
            <th>Start date</th>
            <th>Salary</th>
        </tr>
    </thead>
    }

    const Employes = [
        { name: 'Aaron Tolentino', position: 'System Architect', started: '05/04/24', salary: '$2,760', organization: 'Lich', tasksCompleted: 55 },
        { name: 'Bianca Martinez', position: 'Frontend Developer', started: '12/01/23', salary: '$3,200', organization: 'TechSoft', tasksCompleted: 78 },
        { name: 'Carlos Rodriguez', position: 'UX Designer', started: '03/10/22', salary: '$3,500', organization: 'DesignHub', tasksCompleted: 42 },
        { name: 'Diana Nguyen', position: 'Data Analyst', started: '08/07/23', salary: '$2,900', organization: 'DataWorks', tasksCompleted: 63 },
        { name: 'Evan Thompson', position: 'Backend Developer', started: '11/02/24', salary: '$3,000', organization: 'CodeCraft', tasksCompleted: 60 },
        { name: 'Fiona Smith', position: 'Product Manager', started: '06/09/23', salary: '$3,800', organization: 'Prodigy', tasksCompleted: 49 }
      ];
      
  return (
    <div class="table-wrap">
        <table id="datable_1" class="table table-hover w-100 display pb-30">
            
            <Head/>

            <tbody>
                {Employes.map((item,key)=>
            <tr>
                <td>{item.name}</td>
                <td>{item.position}</td>
                <td>{item.organization}</td>
                <td>{item.tasksCompleted}</td>
                <td>{item.started}</td>
                <td>{item.salary}</td>
            </tr>
                )}
            </tbody>

            <tfoot>
                <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Organization</th>
                    <th>Tasks</th>
                    <th>Start date</th>
                    <th>Salary</th>
                </tr>
            </tfoot>
        </table>
    </div>
  )
}
