import CardDeposit from "./CardDeposit";
import {  useState } from "react";
import { ContractAutomaticVault } from "../../blockchain/AutomaticVault";
// Icons react
import { FaEthereum } from "react-icons/fa";
import {SiBinance} from 'react-icons/si'

export default function DepositContainer({accountStats,accountData}) {

  const _size=40;
  const WidgetCard = "grid grid-cols-1 gap-[24px] lg:grid-cols-3 "

  const [amountToDeposit, setAmountToDeposit] = useState(10000)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);

  const sendMoney = async () => {
    setIsSubmitting(true);
    let object_contract = new ContractAutomaticVault();
    await object_contract.load();
    try {
      let _res = await object_contract.deposit(amountToDeposit);
      console.log('res contract deposit: ', _res);
      setTransactionSuccess(true);
    } catch (error) {
      console.error("Error during deposit: ", error);
      setTransactionSuccess(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className=" w-full">
      
      <div className={`${WidgetCard} mb-[24px]  `}>

      {/* Feedback section for transaction status */}
      {isSubmitting && (
        <div className="text-center my-2">
          <p className="text-blue-500">Processing transaction...</p>
        </div>
      )}
      {transactionSuccess && (
        <div className="text-center my-2">
          <p className="text-green-500">Deposit successful!</p>
        </div>
      )}

        <CardDeposit
          title="USDT"
          value={"$5,456"}
          onClick={sendMoney}
          icon={
           <FaEthereum color={"skyblue"} size={_size} className="2xl:w-20 xl:w-14  2xl:h-[50px] xl:h-[30px] mr-2 " />
          }
          blockchain="Ethereum"
          id="totalEarn"
        />

      </div>

    </div>
  );
}

