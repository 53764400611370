import Web3 from "web3";
import contractJSON from './contracts/AutomaticVault.sol/AutomaticVault.json';
import { getCurrentAccount } from "./Blockchain";

const web3 = new Web3(window.ethereum);

export class ContractAutomaticVault {
    constructor() {
        this.contract = null;
        this.account = '';
    }

    async load() {
        try {
            this.account = await getCurrentAccount();
            console.log('Account:', this.account);

            const contractAddress = '0x5FbDB2315678afecb367f032d93F642f64180aa3'; 
            this.contract = new web3.eth.Contract(contractJSON.abi, contractAddress);

            console.log('Contract loaded:', this.contract);
            return this.contract;
        } catch (error) {
            console.error("Error loading the contract:", error);
            return null;
        }
    }

    async deposit() {
        try {
            const price = await this.getPrice();
            console.log('Price:', price);
            const response = await this.contract.methods.deposit().send({
                from: this.account,
                value: price
            });
            return response;
        } catch (error) {
            console.error('Error on deposit:', error);
            return false;
        }
    }

    async getPrice() {
        try {
            const price = await this.contract.methods.getPrice().call();
            return price;
        } catch (error) {
            console.error('Error fetching price:', error);
            return 0; // Return a default or error code
        }
    }

    // Implement other methods similarly...
}
