/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import Eth from './image/ethereum_1.png';
import Polygon from './image/polygon_1.png';
import Binance from './image/binance_1.png';
// import styled from 'styled-components';
// import { CheckConexion, dameCurrentChain, RequestConexion } from '../../blockchain/Blockchain';
import { SiEthereum } from 'react-icons/si';
import './estilo.css';
import { CheckConexion, RequestConexion, getCurrentChain } from '../../Blockchain';
import { LuWallet } from "react-icons/lu";

const ButtonConnectChain= (
) => {


  // State del sistema
  const [isConnected, setIsConnected] = useState(true);
  const [web3Installed, setWeb3Install] = useState(true);
  const [windowWidth, setWindowWidth] = useState();

  const [BLOCKAIN, setBLOCKCHAIN] = useState({
    name: 'Ethereum',
    id: '0x539',
    image: <SiEthereum color='lightgreen' size='20' />,
    warning: true,
    icon: true,
  });


  const array = [
    { 
      name: 'Ethereum', 
      id: '0x1', 
      icon: true,
      image: <SiEthereum color='skyblue' size={22}/> 
    },
    {
      name: 'Ethereum',
      id: '0x539',
      image: <SiEthereum color='lightgreen' size='22' />,
      warning: true,
      icon: true,
    },
    { name: 'Arbitrum', id: '0xa4b1', image: Eth, warning: true },
    { name: 'BNB', id: '0x38', image: Binance },
    { name: 'Polygon', id: '0x89', image: Polygon },
    { name: 'Polygon Mumbai', id: '0x13881', image: Polygon, warning: true },
    { name: 'Ropsten', id: '0x3', image: Eth, warning: true },
    { name: 'Kovan', id: '0x2a', image: Eth, warning: true },
    { name: 'BNB', id: '0x61', image: Binance, warning: true },
  ];

  useEffect(() => {
    // console.log('is connected? ',isConnected)
    async function Init() {
      const _chainId = await getCurrentChain();
      const respuesta = await CheckConexion();
      const widhtScreen = window.screen.width;

      array.forEach((blockchain_object) => {
        if (blockchain_object.id === _chainId) {
          setBLOCKCHAIN(blockchain_object);
        }
      });

      setWindowWidth(widhtScreen);
      setIsConnected(respuesta.connect);
      setWeb3Install(respuesta.install);
    }
    Init();

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  
  return isConnected ?
    <div
    className="relative  h-[52px] w-[52px]  rounded-[12px] border border-success-300 dark:border-darkblack-400 cursor-pointer"
    >
      
      <p 
      style={{fontSize:'0.5rem',textAlign:'center',paddingTop:'1%'}}
      className='text-white'>{BLOCKAIN.name}</p>

      {BLOCKAIN.icon ? (
        <div className='d-block m-auto align-middle w-fit'>
          {BLOCKAIN.image}
        </div>
      ) : (
        <img className='w-6 align-middle d-block m-auto mt-1' src={BLOCKAIN.image} alt="Blockchain Icon" />
      )}
    </div>
  : 
  // Cellphone
  windowWidth && windowWidth < 821 ?
    <button
      className="button_chains"

      onClick={() =>
        windowWidth < 821 && !isConnected
          ? RequestConexion(setIsConnected, web3Installed)
          :
          !web3Installed
          ? window.open('https://metamask.io/download/')
          : RequestConexion(setIsConnected, web3Installed)
      }
    >
      {web3Installed ? 'Connect Wallet' : windowWidth < 821 && !isConnected ? 'Connect' : 'Download Wallet'}
    </button>
  :

    // Computre
    <button
      onClick={() =>!web3Installed?window.open('https://metamask.io/download/'):RequestConexion(setIsConnected, web3Installed)
      }
      className="button_chains bg-dark d-block m-auto"
    >
      <LuWallet />
    </button>
};

export default ButtonConnectChain;
