import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo_2.png";
import { IoMdHome } from "react-icons/io";
import { FaExchangeAlt, FaWallet, FaTag } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";
import { IoSettingsSharp } from "react-icons/io5";

const Sidebar = ({ activeMenu }) => {

  const size_icon_menu=28;

  const menus = [
    { id: 1, href: "/", title: "Home", icon: <IoMdHome size={size_icon_menu} /> },
    { id: 5, href: "/payroll", title: "PayRoll", icon: <GiPayMoney size={size_icon_menu} /> },
    { id: 2, href: "/trade", title: "Trade", icon: <FaExchangeAlt size={size_icon_menu} /> },
    { id: 3, href: "/wallet", title: "Wallet", icon: <FaWallet size={size_icon_menu} /> },
    { id: 4, href: "/price", title: "Price", icon: <FaTag size={size_icon_menu} /> },
    { id: 6, href: "/settings-profile", title: "Settings", icon: <IoSettingsSharp size={size_icon_menu} /> },
  ];

  return (
    <div className="sidebar">
      <div className="brand-logo">
        <Link to={"/"}>
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="menu">
        <ul>
          {menus.map((item) => (
            <li key={item.id} className={activeMenu == item.id ? "active" : ""}>
              <Link
                to={item.href}
                title={item.title}
                className={activeMenu == item.id ? "active" : ""}
              >
                <span>
                  {/* <i className={item.icon}></i> */}
                  {item.icon}
                  {item.title}
                </span>
              </Link>
            </li>
          ))}

          <li className="logout">
            <Link to={"/signin"} title="Signout">
              <span>
                <i className="icofont-power"></i>
              </span>
            </Link>
          </li>
        </ul>

        <p className="copyright">
          &#169; <Link to={"/#"}>Subcrypto</Link>
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
