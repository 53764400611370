import { useEffect, useRef, useState } from "react";
import Loading2 from "../loader/Loading2/Loading2";
import { BsQrCode } from "react-icons/bs";


export default function CardDeposit({
  value = "",
  title,
  icon,
  showChart = true,
  showPercent = true,
  blockchain,
  onClick
}) {
  const [loading, setLoading] = useState(true);

  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 450);
    gradient.addColorStop(0, "rgba(34, 197, 94,0.41)");
    gradient.addColorStop(0.2, "rgba(255, 255, 255, 0)");
    return gradient;
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      // const fetchedValue = "1234"; // Reemplaza con tu lógica real
      // setLoadedValue(fetchedValue);
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const ctx = chartRef?.current?.getContext("2d")?.chart.ctx;
    if (ctx) {
      const gradient = createGradient(ctx);
      chartRef.current.data.datasets[0].backgroundColor = gradient;
      chartRef.current.update();
    }
  }, []);



  return (
    <div className="hover:animate-pulse_bg px-5 py-8 rounded-lg bg-darkblack-600 border-2 border-transparent hover:border-success-200 min-h-[211px] ">
      <div className="flex justify-between items-center mb-5 md:mb-8 lg:mb-5 h-fit">
        <div className="flex space-x-[7px] items-center">
          <span className="text-2xl lg:text-xl xl:text-sm 2xl:text-xl text-white font-semibold dark:text-white">
            {title}
          </span>
        </div>
        <div className="icon ">
          <span>{icon}</span>
        </div>
      </div>
      <div className="flex justify-between items-end h-fit">
        <div className="flex-1">
          {loading ? (
            <span className="text-bgray-900 font-bold text-2xl xl:text-xl 2xl:text-3xl leading-[48px] dark:text-white">
              <Loading2 className="h-10" />
            </span>
          ) : (
            <p className="text-bgray-900 font-bold text-2xl xl:text-xl 2xl:text-3xl leading-[48px] dark:text-white ">
              {value}
            </p>
          )}
          {showPercent && (
            <div className="flex items-center space-x-2">
              <div className="flex justify-end items-start 2xl:flex-row xl:flex-col flex-row space-x-2 xl:space-x-0 2xl:space-x-2">
                <span className="2xl:text-sm lg:text-xs text-base font-semibold text-white">
                  {blockchain}
                </span>
              </div>
            </div>
          )}
        </div>


        {showChart && (
          <div className="w-1/3 h-fit xl:h-14 2xl:h-[68px] text-white">
            <BsQrCode size={80}/>
          </div>
        )} 
        <button 
        onClick={onClick}
        className=" bg-green-600 text-white rounded p-1 hover:bg-green-500">Deposit</button>
      </div>
    </div>
  );
}

