import React from 'react'

export default function PayRollHeader() {
  return (
    <div className="hk-pg-header">
    <div>
        <h2 className="hk-pg-title font-weight-600 mb-10">Statistics Dashboard</h2>
        <p>Earnings from subscriptions that stared in the period 1 - 30 May 2024
            <i className="ion ion-md-help-circle-outline ml-5" 
        data-toggle="tooltip" data-placement="top" title="Need help about earning stats"></i></p>
    </div>

    <div className="d-flex">
        <div className="btn-group btn-group-sm" role="group">
            <button type="button" className="btn btn-outline-light active">today</button>
            <button type="button" className="btn btn-outline-light">week</button>
            <button type="button" className="btn btn-outline-light">month</button>
            <button type="button" className="btn btn-outline-light">quarter</button>
            <button type="button" className="btn btn-outline-light">year</button>
        </div>
    </div>
</div>
  )
}
