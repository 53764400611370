import React from 'react'

export default function payRollNotification() {
  return (
    <div>
        <div class="alert alert-primary alert-wth-icon alert-dismissible fade show" role="alert">
        <span class="alert-icon-wrap"><i class="zmdi zmdi-help"></i></span> 
        The next payment is in 47 hours and 50 minutes
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
        </div>

    </div>
  )
}
