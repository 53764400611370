import Web3 from "web3"

const web3=new Web3(window.ethereum)

let currentAccount;

const deepLink="https://metamask.app.link/dapp/bluff-66a41.web.app/"


export const getCurrentAccount=async()=>{
  try {
    
    await window.ethereum
    .request({ method: 'eth_accounts' })
    .then((accounts)=>{
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        console.log('Please connect to MetaMask.');
        // window.ethereum.request({ method: 'eth_requestAccounts' })

      } else if (accounts[0] !== currentAccount) {
        currentAccount = accounts[0];
        // console.log("accounts: ",accounts)
      }
  
    })
    .catch((err) => {
      console.error("Error en actualizarCuenta: Blockchain.js",err);
    })

    } catch (error) {
      console.log('error en Pago.js: ',error)
      return 0
      
    }

    if(currentAccount==null) return 0
    return web3.utils.toChecksumAddress(currentAccount)
    
}

export const RequestConexion=async(setIsConnected,web3Installed=false)=>{

  const widhtScreen=window.screen.width
  //Si la ventana es menor a 820, el usuario esta en un dispositivo movil

  if(widhtScreen<821){
    //Es menor a la dimension de un Ipad air, debe ser portatil
    if(web3Installed){
      const res=await window.ethereum.request({ method: 'eth_requestAccounts' })
      if(res.length>0)setIsConnected(true)
      
    }else{
      window.open(deepLink)
    }

  }else{
    console.log('not connected to blockchain 1')
    if(web3Installed){
      console.log('not connected to blockchain 3')
      const res=await window.ethereum.request({ method: 'eth_requestAccounts' })
      if(res.length>0)setIsConnected(true)
    }else{
      console.log('not connected to blockchain 2')
    }
  }
  
}


export const colocarBotonConnect=()=>{
    window.ethereum.on('accountsChanged',(acc)=>{
      console.log("cuenta cambiada: ",acc[0])
      // window.location.reload(false);

    });
  
}

export const CancelarListener=async()=>{
    window.ethereum.removeListener('accountsChanged',()=>{
      console.log("oidos de cambio de cuenta desactivados")
    });
}

export const prenderCambioCuenta=()=>{
  try {

    window.ethereum.on('accountsChanged',(acc)=>{
      console.log("cuenta cambiada: ",acc[0])
      window.location.reload();
    });
  } catch (error) {
    console.log('prenderCambioCuenta: ',error)
  }
  
}


export const prenderCambioCadena=()=>{
    let chain=''
    try {
      window.ethereum.on('chainChanged', (_chainId) =>{
          console.log('chainsdjkasbdjasbdjkas: ',_chainId)
          chain= _chainId
          window.location.reload()
      });
    } catch (error) {
        console.log('prenderCambioCadena: ',error)
    }
  return chain
}


export const CheckConexion=async()=>{
  let isConnected=false
  let isInstall=true

  if (window.ethereum !== undefined) {
    // console.log('window.ethereum: ',window.ethereum)

    try {
      await window.ethereum
      .request({ method: 'eth_accounts' })
      .then((accounts)=>{
        if (accounts.length != 0) {
          // MetaMask is locked or the user has not connected any accounts
          isConnected=true           
          
        }
      })

    } catch (error) {
      console.log('error CheckConexion: ',error)
    }

  }else{
      //The user doesnt have install a web3Wallet
      isInstall=false
      
  }
  return {connect:isConnected,install:isInstall}
}

export const getCurrentChain=async()=>{
  try {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    return chainId  
  } catch (error) {
    return 0
  }


}

export const TransformWei=(_num)=>{
  try {
  return web3.utils.fromWei(_num,'ether')
} catch (error) {
    console.log('error TransformWei=>',error)
    return 0
}
}

export const TransformarToWei=(_num)=>{
  return web3.utils.toWei(_num,'ether')
}

export const ChangeChain=async(_id=import.meta.env.VITE_CHAIN)=>{
  // console.log('dajsdbaskjdbaskjdbas  id :',_id)
  await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: _id}]});
}

export const AddChain=async(_id=import.meta.env.VITE_CHAIN)=>{

  try {
    await window.ethereum.request({ method: 'wallet_switchEthereumChain', params:[{chainId: _id}]});

  } catch (addError) {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId:_id,
          chainName:import.meta.env.VITE_CHAIN_NAME,
          rpcUrls: [import.meta.env.VITE_CHAIN_RPC
          ],
          nativeCurrency:{
            name:"BNB Tesntnet",
            symbol:"tBNB",
            decimals:18
          }
        },
      ],
    });
  }
}


