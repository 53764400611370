import React from 'react'

export default function PayRollMainData() {
  return (
    <div className="hk-row">
    <div className="col-md-4">
        <div className="card card-sm">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <span className="d-block font-12 font-weight-500 text-dark text-uppercase mb-5">budget</span>
                        <span className="d-block display-6 font-weight-400 text-dark">$12,783</span>
                    </div>
                    <div>
                        <div id="sparkline_4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-md-4">
        <div className="card card-sm">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <span className="d-block font-12 font-weight-500 text-dark text-uppercase mb-5">Total hours</span>
                        <span className="d-block display-6 font-weight-400 text-dark">659</span>
                    </div>
                    <div>
                        <div id="sparkline_5"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-md-4">
        <div className="card card-sm">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <span className="d-block font-12 font-weight-500 text-dark text-uppercase mb-5">Progress</span>
                        <span className="d-block display-6 font-weight-400 text-dark">91.42%</span>
                    </div>
                    <div>
                        <span id="pie_chart_1" className="d-flex easy-pie-chart" data-percent="91">
                            <span className="percent head-font">91</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
